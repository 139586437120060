.maincontainer{
/* width: 100%; */
text-align: center;
margin: 20px 0px;

}
.container1{
   /* margin: 5%; */
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 50px;

}
.maincontainer h2{
    color: burlywood;
    font-weight: 700;
}
button{
    margin: 10px;
    width: 180px;
    height: 62px;
    border-radius: 1px;
    background-color: black;
    color: burlywood;
    font-size: 18px;
    font-weight: 700;
}
button:hover{
    background-color: burlywood;
    color: black;
}