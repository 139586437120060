/* footer section start */
/* * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
  } */
  /* body {
    font-size: 14px;
    background: #fff;
    max-width: 1920px;
    margin: 0 auto;
    overflow-x: hidden;
    font-family: poppins;
  } */
  #footer {
    background: #f7f7f7;
    padding: 3rem;
    /* padding-top: 5rem; */
    padding-top: 7rem;
    padding-bottom: 80px;
    background-image: url(https://arena.km.ua/wp-content/uploads/3538533.jpg);
  }
  #logoimg{
    width: 250px;
    height: 150px;
  }
  #footer2 {
    background: #f7f7f7;
    padding: 3rem;
    margin-top: 0px;
    /* padding-top: 5rem; */
    padding-top: 7rem;
    padding-bottom: 80px;
    /* background-image: url(../images/cards/v748-toon-111.png); */
  }
  .logo-footer {
    /* max-width: 300px; */
  }
  .social-links {
    /* display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; */
  }
  .footer-about{
    font-size: 12px;
    line-height: normal;
    text-align: left;
  }
  .social-links h2 {
    padding-bottom: 15px;
    font-size: 20px;
    font-weight: 600;
  }
  .social-links img {
    padding-bottom: 25px;
  }
  .social-icons {
    /* display: flex;
      gap: 3rem; */
    display: flex;
    flex-direction: column;
    gap: 1rem;
    color: #777777;
  }
  .social-icons a {
    /* font-size: 18px; */
    /* background: #ffffff; */
    /* box-shadow: rgb(0 0 0 / 8%) 0px 4px 12px;
      padding: 0.4rem 1rem 0.4rem 1rem;
      border-radius: 3px;
      color: #82074a; */
    /* margin-right: 18px; */
    color: #777777;
  }
  .social-icons a:hover {
    color: #000;
  }
  .social-icons a i {
    box-shadow: rgb(0 0 0 / 8%) 0px 4px 12px;
    padding: 0.4rem 1rem 0.4rem 1rem;
    border-radius: 3px;
    color: #82074a;
    font-size: 16px;
    margin-right: 12px;
  }
  li {
    list-style: none;
  }
  .useful-link h2 {
    padding-bottom: 15px;
    font-size: 20px;
    font-weight: 600;
  }
  .useful-link img {
    padding-bottom: 15px;
  }
  .use-links {
    line-height: 32px;
  }
  .use-links li i {
    font-size: 14px;
    padding-right: 8px;
    color: #898989;
  }
  .use-links li a {
    color: #303030;
    font-size: 15px;
    font-weight: 500;
    color: #777777;
  }
  .use-links li a:hover {
    color: #000;
  }
  .address h2 {
    padding-bottom: 15px;
    font-size: 20px;
    font-weight: 600;
  }
  .address img {
    padding-bottom: 15px;
  }
  .address-links li a {
    color: #303030;
    font-size: 15px;
    font-weight: 500;
    color: #777777;
  }
  .address-links li i {
    font-size: 16px;
    padding-right: 8px;
    color: #82074a;
  }
  .address-links li i:nth-child(1) {
    padding-top: 9px;
  }
  .address-links .address1 {
    font-weight: 500;
    font-size: 15px;
    display: flex;
  }
  .address-links {
    line-height: 32px;
    color: #777777;
  }
  .copy-right-sec {
    padding: 1.8rem;
    background: black;
    color: #fff;
    text-align: center;
  }
  .copy-right-sec a {
    color: #fcd462;
    font-weight: 500;
  }
  a {
    text-decoration: none;
  }
  
  /* footer section end */
  